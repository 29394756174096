import cloneDeep from 'lodash/cloneDeep'
import pick from 'lodash/pick'
import config from 'config'

const initialStateFactory = (defaultState) => {
  // storing default values for the fields that will be set in createApp
  const defaultFields = pick(defaultState, ['version', 'config', '__DEMO_MODE__', 'storeView', '__SSR_CACHE__'])

  const createInitialState = (currentState) => {
    const storeView = config?.storeViews?.[currentState.storeView.storeCode] || { storeCode: currentState.storeView.storeCode }

    return {
      ...cloneDeep(currentState),
      ...defaultFields,
      storeView
    }
  }

  return { createInitialState }
}

export default initialStateFactory
